import React from "react";

const Input = ({language, value, onChange, onClear}) => {

    const handleKeyDown = (e) => {
        if (e.key === "Enter" || e.charCode === 13 || e.keyCode === 13) {
            e.preventDefault();
        }

        if (e.key === "Escape" || e.charCode === 27 || e.keyCode === 27) {
            onClear();
        }
    }

    const inputChanged = (e) => onChange(e.currentTarget.value);

    const handleChange = (e) => {

        if (e.currentTarget.value.length > 0) {
            document.querySelector('.input-descr').style.display = 'none';
        } else {
            document.querySelector('.input-descr').style.display = 'flex';
        }

        inputChanged(e);

    }

    return (
        <>
            <label className="hidden" htmlFor="password">{language.placeholder}</label>
            <input
                readonly="readonly"
                id="password"
                className="password-input"
                type="password"
                autoFocus={true}
                value={value}
                placeholder={language.placeholder}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            <div className="input-descr">Entries are 100% secure and not stored in anyway or shared with anyone</div>
        </>
    );
}

export default Input;
