import setup from "hsimp-purescript";

import characterSets from "hsimp-purescript/data/character-sets";
import common from "hsimp-purescript/data/common/top10";
import patterns from "hsimp-purescript/data/patterns";

import {
    CHANGE_HSIMP,
    CHANGE_COMMON,
    changeHSIMP,
} from "data/actions/state";

const regenerate = ({ calculationsPerSecond, namedNumbers, language, common }) => setup({
    calculationsPerSecond,
    namedNumbers,
    language,
    checks: { characterSets, common, patterns }
});

export default ({ dispatch, getState }) => {
    const { calculationsPerSecond, namedNumbers, language } = getState();
    let cache = { calculationsPerSecond, namedNumbers, language, common };
    let hsimp = null;

    return next => action => {
        // run reducers
        // unless changing common passwords
        const returnValue = action.type === CHANGE_COMMON ? action : next(action);

        // move on if its this enhancer's action
        // otherwise loopy behaviour ensues
        if (action.type === CHANGE_HSIMP) {
            return returnValue;
        }

        const { loaded, calculationsPerSecond, namedNumbers, language, password } = getState();

        // if there's a language set and any of the settings have changed
        if (
            loaded && (
                cache.calculationsPerSecond !== calculationsPerSecond ||
                cache.namedNumbers !== namedNumbers ||
                cache.language !== language ||
                action.type === CHANGE_COMMON
            )
        ) {
            if (process.env.NODE_ENV !== "production") {
                console.log("Regenerating HSIMP");
            }

            // update cache
            cache = {
                calculationsPerSecond,
                namedNumbers,
                language,
                common: action.type === CHANGE_COMMON ? action.common : cache.common,
            }

            // regenerate hsimp function
            hsimp = regenerate(cache);
        }

        // assuming HSIMP has been generated
        if (hsimp) {
            dispatch(changeHSIMP(hsimp(password)));
        }

        return returnValue
    }
}
