import React from "react";

import Check from "./Check";
import Advertising from "./Advertising";

const CheckList = ({ checks, language }) => !checks.length ? null : (
    <ul className="checks">
        <Advertising language={ language.advertising.check } />
        {
            checks.map(check => (
                <Check key={ check.name } level={ check.level } name={ check.name } message={ check.message } />
            ))
        }
    </ul>
);

export default CheckList;
