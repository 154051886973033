import { connect } from "react-redux";
import _ from "lib/compose";

import { logImpression } from "data/actions/ga";

import Sponsor from "./component";

const mapStateToProps = ({ language }) => ({
    img: language.advertising.img,
    href: language.advertising.href,
});

const mapDispatchToProps = dispatch => ({
    onMounted: _(dispatch, logImpression),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sponsor);
