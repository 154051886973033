import React, { useEffect } from "react";

const Legacy = ({ onMounted }) => {
    useEffect(onMounted, []);

    return (
        <div className="result text-block">
            <p className="result__text result__time">Your browser is really old!</p>
            <p>This site is not compatible with your browser.</p>
            <p>If you're concerned about security, you should <a href="http://browsehappy.com">update your browser to the latest version</a>.</p>
        </div>
    );
};

export default Legacy;
