export default {
    loaded: false,
    legacy: false,
    adLink: null,
    language: {
        header: {
            title: "How Secure Is My Password?"
        }
    },
    password: "",
    calculationsPerSecond: 40e9,
    namedNumbers: true,
    time: null,
    checks: [],
    level: null,
};
