import { connect } from "react-redux";

import Loading from "./component";
import { firstLoad } from "data/actions/api";

import _ from "lib/compose";

const mapStateToProps = ({ loaded }) => ({ loaded });

const mapDispatchToProps = dispatch => ({
    onMounted: _(dispatch, firstLoad),
});

export default connect(mapStateToProps, mapDispatchToProps)(Loading);
