import wretch from "wretch";
import _ from "lib/compose";

import { changeLanguage, changeCommon, legacy } from "data/actions/state";
import { logPlatform } from "data/actions/ga";
import getPlatform from "data/platform";
import inject from "data/inject";

const defaultLanguage = "en";

const map = {
    en: "english",
    fr: "french",
    es: "spanish",
};

const setHTMLlang = lang => document.documentElement.setAttribute("lang", lang);
const getBrowserLanguage = () => navigator.languages ? navigator.languages[0] : (navigator.language || navigator.userLanguage);

export const firstLoad = () => dispatch => {
    if (!window.fetch) {
        return dispatch(legacy());
    }

    const browserLanguage = getBrowserLanguage();
    const languageCode = map[browserLanguage] ? browserLanguage : defaultLanguage;
    const language = map[languageCode];
    const platform = getPlatform();

    setHTMLlang(languageCode);

    dispatch(logPlatform(platform));

    wretch(`/language/${language}.json`).get().json(_(dispatch, changeLanguage, inject(platform)));
    wretch("/data/top10k.json").get().json(_(dispatch, changeCommon));
};
