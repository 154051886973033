import React from "react";

const Header = ({ title, banner_img, mobile_banner_img, href }) => (
    <div className="top-banner">

        <h1 role="banner" className="header title">{ title }</h1>

    </div>
);

export default Header;
