export default () => {
    if (process.env.NODE_ENV === "development" && process.env.REACT_APP_PLATFORM) {
        return process.env.REACT_APP_PLATFORM;
    }

    if (navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
        return "ios";

    }

    if (/(android)/i.test(navigator.userAgent)) {
        return "android";
    }

    return "web";
};
