import { connect } from "react-redux";

import Header from "./component";

const mapStateToProps = ({ language }) => ({
    title: language.header.title,
    href: language.header.href,
    banner_img: language.header.banner_img,
    mobile_banner_img: language.header.mobile_banner_img,
});

export default connect(mapStateToProps)(Header);
