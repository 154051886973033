import React from "react";

const Check = ({ level, name, message }) => (
    <div className={ `checks__item checks__level checks__level--${level}` }>
        <h3 className="block__header header header--sub-title">
            <div className="block">{ name }</div>
        </h3>
        <div className="block__body checks__item">
            <div className="block" dangerouslySetInnerHTML={{__html: message }} />
        </div>
    </div>
);

export default Check;
