import { connect } from "react-redux";
import _ from "lib/compose";

import { logLegacy } from "data/actions/ga";

import Legacy from "./component";

const mapDispatchToProps = dispatch => ({
    onMounted: _(dispatch, logLegacy),
});

export default connect(null, mapDispatchToProps)(Legacy);
