const getClass = level => {
    switch (level) {
        case "insecure": return "bad";
        case "warning": return "ok";
        case "notice": return "ok";
        case "achievement": return "good";
        default: return "";
    }
};

export default store => {
    store.subscribe(() => {
        const { level } = store.getState();
        document.documentElement.setAttribute("class", getClass(level));
    });
};
