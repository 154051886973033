import React from "react";

const Result = ({ password, time, language, text, tweet }) => password === "" ? null : (
    <div className="result">
        <p className="result__text result__before">{ language[text].pre }</p>
        <p className="result__text result__time">{ time }</p>
        <p className="result__text result__after">{ language[text].post }</p>
        <p className="result__text result__advertising" dangerouslySetInnerHTML={{ __html: language[text].advertising }} />

        <p className="result__buttons">
            <a
                className="result__link"
                href={ `http://twitter.com/home/?status=${tweet}%0d%0dhttp://hsim.pw` }
            >
                Tweet Your Result
            </a>
        </p>

    </div>
);

export default Result;
