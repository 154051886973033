import {
    LEGACY,
    CLEAR_PASSWORD,
    CHANGE_PASSWORD,
    CHANGE_LANGUAGE,
    CHANGE_NAMED_NUMBERS,
    CHANGE_HSIMP,
} from "./actions/state";

const clearPassword = state => ({ ...state, password: "" });

const changePassword = (state, { password }) => ({ ...state, password });

const changeNamedNumbers = (state, { namedNumbers }) => ({ ...state, namedNumbers });

const changeLanguage = (state, { language }) => ({
    ...state,
    language,
    loaded: true
});

const changeHSIMP = (state, { hsimp }) => ({ ...state, ...hsimp });

const legacy = (state) => ({
    ...state,
    legacy: true,
    loaded: true,
});

export default (state, action) => {
    switch (action.type) {
        case CHANGE_NAMED_NUMBERS: return changeNamedNumbers(state, action);
        case CHANGE_LANGUAGE: return changeLanguage(state, action);
        case CHANGE_PASSWORD: return changePassword(state, action);
        case CHANGE_HSIMP: return changeHSIMP(state, action);
        case CLEAR_PASSWORD: return clearPassword(state);
        case LEGACY: return legacy(state);
        default: return state;
    }
};
