import React, { useEffect } from "react";

const Sponsor = ({ href, img, onMounted }) => {
    useEffect(onMounted, []);

    return (
        <div className="sponsor">
            
            <a className="sponsor-btn" href="https://www.security.org/how-secure-is-my-password/" target="_blank">

                <strong>Please Note:</strong> This tool is now being maintained over at <span>Security.org</span>

            </a>
            
        </div>
    );
};

export default Sponsor;
