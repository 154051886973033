import React from "react";

import Header from "components/Header";
import Input from "components/Input";
import Result from "components/Result";
import Checks from "components/Checks";
import Sponsor from "components/Sponsor";
import Footer from "components/Footer";
import Loading from "components/Loading";
import Legacy from "components/Legacy";

const App = ({ legacy }) =>  (
    <Loading>
        <Header />
        { legacy ? <Legacy /> : (
            <>
                <main className="content">
                    <Input />
                    <Result />
                    <Checks />
                    <Sponsor />
                </main>
                <Footer />
            </>
        )}
    </Loading>
);

export default App;
