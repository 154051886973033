import React from "react";

const Advertising = ({ language }) => (
    <div className="checks__item checks__level checks__level--advertising">
        <h3 className="block__header header header--sub-title">
            <div className="block">{ language.title }</div>
        </h3>
        <div className="block__body checks__item">
            <div className="block" dangerouslySetInnerHTML={{__html: language.message }} />
        </div>
    </div>
);

export default Advertising;
