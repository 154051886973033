import React, { useEffect } from "react";
import "./component.css";

const Loading = ({ children, loaded, onMounted }) => {
    useEffect(() => {
        if (!loaded) {
            onMounted();
        }
    }, [loaded, onMounted]);

    return loaded ? children : (
        <div className="loading"><div></div><div></div><div></div><div></div></div>
    );
}

export default Loading;
