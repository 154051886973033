import { connect } from "react-redux";

import Result from "./component";

let getText = (level) => {
    switch (level) {
        case "insecure": return "instant";
        case "warning": return "bad";
        case "notice": return "ok";
        default: return "regular";
    }
}

const mapStateToProps = ({ password, time, level, language }) => {
    const text = time === language.forever ? "infinite" : getText(level);
    const tweet = `${language.result[text].tweet_pre}${time}${language.result[text].tweet_post}`;

    return {
        password,
        time,
        tweet,
        text,
        language: language.result,
    };
};

export default connect(mapStateToProps)(Result);
