export const LEGACY = "LEGACY";

export const legacy = () => ({
    type: LEGACY,
});

export const CLEAR_PASSWORD = "CLEAR_PASSWORD";

export const clearPassword = () => ({
    type: CLEAR_PASSWORD,
});

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const changePassword = password => ({
    type: CHANGE_PASSWORD,
    password,
});

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export const changeLanguage = language => ({
    type: CHANGE_LANGUAGE,
    language,
});

export const CHANGE_COMMON = "CHANGE_COMMON";

export const changeCommon = common => ({
    type: CHANGE_COMMON,
    common,
});

export const CHANGE_NAMED_NUMBERS = "CHANGE_NAMED_NUMBERS";

export const setNamedNumbers = namedNumbers => ({
    type: CHANGE_NAMED_NUMBERS,
    namedNumbers,
});

export const CHANGE_HSIMP = "CHANGE_HSIMP";

export const changeHSIMP = hsimp => ({
    type: CHANGE_HSIMP,
    hsimp,
});
