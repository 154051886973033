import { connect } from "react-redux";
import _ from "lib/compose";

import Input from "./component";
import { changePassword, clearPassword } from "data/actions/state";

const mapStateToProps = ({ language, password }) => ({
    language: language.input,
    value: password,
});

const mapDispatchToProps = dispatch => ({
    onChange: _(dispatch, changePassword),
    onClear: _(dispatch, clearPassword),
});

export default connect(mapStateToProps, mapDispatchToProps)(Input);
