import { createStore, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";
import hsimp from "middleware/hsimp";

import reducer from "data/reducer";
import initial from "data/initial";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
    reducer,
    initial,
    composeEnhancers(applyMiddleware(hsimp, thunk)),
);
