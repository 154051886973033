import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import store from "data/store";

import * as serviceWorker from "serviceWorker";

import body from "body";
import App from "components/App";

// updates the body class
body(store);

// render React components
ReactDOM.render(
    <React.StrictMode>
        <Provider store={ store }>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// service worker
serviceWorker.unregister();
