const replace = (text, link) => text ? text.replace(/{{link}}/g, `href="${link}"`) : null;

export default platform => language => {
    const link = language.advertising.links[platform];

    const results = Object.keys(language.result);

    results.forEach(key => {
        language.result[key].advertising = replace(language.result[key].advertising, link)
    });

    language.advertising.check.message = replace(language.advertising.check.message, link);

    language.checks.forEach((_, i) => {
        language.checks[i].message = replace(language.checks[i].message, link);
    });

    language.advertising.link = link;

    return language;
}
