import React from "react";

const Footer = ({ loaded, language }) => !loaded ? null : (
    <div role="contentinfo" className="footer">
        
        <p className="privacy-link"><a href="/privacy-policy.html">Privacy Policy</a></p>

        <p className="footer__disclaimer">{ language.disclaimer }</p>

        <div id="cookie-bar" className="cookie-bar">
            <div className="container">
                <div className="bar-content">
                    <strong>Cookie Preferences</strong>We use cookies on our site. Some are essential for the site to work, while others help with functionality and performance.  For more details, including how we manage cookies, please review our <a href="/privacy-policy.html">privacy policy</a>.
                </div>
                <div className="bar-links">
                    <a href="#" className="cb-accept">I Accept</a>
                    <a href="#" className="cb-accept text">Continue without accepting</a>
                </div>
            </div>
        </div>
    </div>
    
);

export default Footer;
