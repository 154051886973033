import { connect } from "react-redux";

import Footer from "./component";

const mapStateToProps = ({ loaded, language }) => ({
    loaded,
    language: language.footer,
});

export default connect(mapStateToProps)(Footer);
